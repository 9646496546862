<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/1.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC HEEL GROUNDER
                </h4>
                <h5 style="text-align: center">SINT-201</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/2.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ECONOMIC ANTI-STATIC HEEL GROUNDER
                </h4>
                <h5 style="text-align: center">SINT-201E</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/3.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC HEEL GROUNDER WITH EXTERNAL RESISTOR
                </h4>
                <h5 style="text-align: center">SINT-201R</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
           <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/4.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC HEEL GROUNDER WITH SHIN BAND
                </h4>
                <h5 style="text-align: center">SINT-202</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
           <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/5.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC TOE GROUNDER

                </h4>
                <h5 style="text-align: center">SINT-203</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/6.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC TOE GROUNDER

                </h4>
                <h5 style="text-align: center">SINT-203B</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/7.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC TOE GROUNDER

                </h4>
                <h5 style="text-align: center">SINT-203C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/8.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC TOE GROUNDER

                </h4>
                <h5 style="text-align: center">SINT-203D</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/9.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  QUICK RELEASE HEEL GROUNDER
                </h4>
                <h5 style="text-align: center">SINT-211 /SINT-211Y</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/10.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  QUICK RELEASE HEEL WITH EXTERNAL RESISTOR

                </h4>
                <h5 style="text-align: center">SINT-211R</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
         <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/11.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  D-RING FASTENING HEEL GROUNDER

                </h4>
                <h5 style="text-align: center">SINT-212</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/12.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  FULL RUBBER CUP HEEL GROUNDER

                </h4>
                <h5 style="text-align: center">SINT-215</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/13.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  QUICK RELEASE FULL RUBBER CUP HEEL GROUNDER

                </h4>
                <h5 style="text-align: center">SINT-216</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/14.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  FULL RUBBER CUP TOE & HEEL GROUNDER


                </h4>
                <h5 style="text-align: center">SINT-237</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/15.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  MOLDED RESISTORS
 

                </h4>
                <h5 style="text-align: center">SINT-001</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/16.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ECONOMIC HEEL GROUNDER
 

                </h4>
                <h5 style="text-align: center">SINT-201AB</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/17.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 DETACHABLE ANTI-STATIC HEEL GROUNDERS WITH SHIN BAND


                </h4>
                <h5 style="text-align: center">SINT-202S</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/18.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  DETACHABLE ANTI-STATIC HEEL GROUNDERS



                </h4>
                <h5 style="text-align: center">SINT-211S</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/19.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  BLUE CONDUCTIVE RIBBON

 

                </h4>
                <h5 style="text-align: center">SINT-WL-21</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/20.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CONDUCTIVE INSOLE
 

                </h4>
                <h5 style="text-align: center">SINT-WL-22</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/heelgrounders">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/21.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 CONDUCTIVE PP COMPOUND



                </h4>
                <h5 style="text-align: center">SINT-WL-23</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>